<template>
  <div v-if="!isLoadingData" class="pa-5 flex-grow-1">
    <div>
      <v-subheader>General permissions</v-subheader>
      <div class="team-modules-container">
        <div
          v-for="permissionOption in generalPermissionOptions"
          :key="permissionOption.column"
          :class="{
            'team-module-active': isGeneralPermissionActive(
              permissionOption.column
            ),
          }"
          class="team-module d-flex flex-column align-center justify-center ant-glass-background"
        >
          <div class="team-module-overlay">
            <v-btn
              v-if="!selectedTeam.is_admin"
              class="team-module-overlay-btn"
              small
              @click="
                updateGeneralPermission(
                  permissionOption.column,
                  !generalPermissions[permissionOption.column]
                )
              "
            >
              {{
                isGeneralPermissionActive(permissionOption.column)
                  ? 'disable'
                  : 'enable'
              }}
            </v-btn>
            <v-tooltip v-else bottom>
              <template #activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-circle-off-outline
                </v-icon>
              </template>
              <span>Cannot disable for admin team</span>
            </v-tooltip>
          </div>
          <v-icon
            :class="{
              inactive: !isGeneralPermissionActive(permissionOption.column),
            }"
            color="primary"
          >
            {{ permissionOption.icon }}
          </v-icon>
          <div
            :class="{
              inactive: !isGeneralPermissionActive(permissionOption.column),
            }"
          >
            {{ permissionOption.column }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-subheader>Workflows permissions</v-subheader>
      <div class="team-modules-container">
        <div
          v-for="permissionOption in workflowPermissionOptions"
          :key="permissionOption.column"
          :class="{
            'team-module-active':
              (isWorkflowPermissionActive(permissionOption.column) &&
                generalPermissions.workflows) ||
              selectedTeam.is_admin,
          }"
          class="team-module d-flex flex-column align-center justify-center ant-glass-background"
        >
          <div class="team-module-overlay">
            <v-btn
              v-if="generalPermissions.workflows && !selectedTeam.is_admin"
              class="team-module-overlay-btn"
              small
              @click="
                updateWorkflowPermission(
                  permissionOption.column,
                  !workflowPermissions[permissionOption.column]
                )
              "
            >
              {{
                isWorkflowPermissionActive(permissionOption.column)
                  ? 'disable'
                  : 'enable'
              }}
            </v-btn>
            <v-tooltip v-else bottom>
              <template #activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-circle-off-outline
                </v-icon>
              </template>
              <span>{{
                selectedTeam.is_admin
                  ? 'Cannot disable for admin team'
                  : 'General module is disabled cannot set permissions'
              }}</span>
            </v-tooltip>
          </div>
          <v-icon
            :class="{
              inactive:
                (!isWorkflowPermissionActive(permissionOption.column) ||
                  !generalPermissions.workflows) &&
                !selectedTeam.is_admin,
            }"
            color="primary"
          >
            {{ permissionOption.icon }}
          </v-icon>
          <div
            :class="{
              inactive:
                (!isWorkflowPermissionActive(permissionOption.column) ||
                  !generalPermissions.workflows) &&
                !selectedTeam.is_admin,
            }"
          >
            {{ permissionOption.column }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <v-subheader>SBS permissions</v-subheader>
      <div class="team-modules-container">
        <div
          v-for="permissionOption in sbsPermissionOptions"
          :key="permissionOption.column"
          :class="{
            'team-module-active': isSbsPermissionActive(
              permissionOption.column
            ),
          }"
          class="team-module d-flex flex-column align-center justify-center ant-glass-background"
        >
          <div class="team-module-overlay">
            <v-btn
              v-if="generalPermissions.sbs"
              class="team-module-overlay-btn"
              small
              @click="
                updateSbsPermission(
                  permissionOption.column,
                  !sbsPermissions[permissionOption.column]
                )
              "
            >
              {{
                isSbsPermissionActive(permissionOption.column)
                  ? 'disable'
                  : 'enable'
              }}
            </v-btn>
            <v-tooltip v-else bottom>
              <template #activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-circle-off-outline
                </v-icon>
              </template>
              <span>{{
                selectedTeam.is_admin
                  ? 'Cannot disable for admin team'
                  : 'General module is disabled cannot set permissions'
              }}</span>
            </v-tooltip>
          </div>
          <v-icon
            :class="{
              inactive: !isSbsPermissionActive(permissionOption.column),
            }"
            color="primary"
          >
            {{ permissionOption.icon }}
          </v-icon>
          <div
            :class="{
              inactive: !isSbsPermissionActive(permissionOption.column),
            }"
          >
            {{ permissionOption.column }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <v-subheader>Modules</v-subheader>
      <div class="team-modules-container">
        <div
          v-for="module in project.modules"
          :key="module.id"
          :class="{ 'team-module-active': isModuleActive(module) }"
          class="team-module d-flex flex-column align-center justify-center ant-glass-background"
        >
          <div class="team-module-overlay">
            <v-btn
              v-if="!selectedTeam.is_admin"
              :loading="module.loading"
              class="team-module-overlay-btn"
              small
              @click="clickModuleForTeam(module)"
            >
              {{ isModuleActive(module) ? 'disable' : 'enable' }}
            </v-btn>
            <v-tooltip v-else bottom>
              <template #activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-circle-off-outline
                </v-icon>
              </template>
              <span>Cannot disable for admin team</span>
            </v-tooltip>
          </div>
          <v-icon
            :class="{ inactive: !isModuleActive(module) }"
            color="primary"
          >
            {{ module.icon ? module.icon : 'mdi-application' }}
          </v-icon>
          <div :class="{ inactive: !isModuleActive(module) }">
            {{ module.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center fill-height flex-1">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createTeamGeneralPermissions,
  createTeamModuleRelation,
  createTeamSbsPermissions,
  createTeamWorkflowPermissions,
  deleteTeamModuleRelation,
  getTeamGeneralPermissions,
  getTeamModules,
  getTeamSbsPermissions,
  getTeamWorkflowPermissions,
} from '@/services/api/teams.api';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'ProjectTeamModules',
  components: { AntLoading },
  props: {
    selectedTeam: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      teamModules: [],
      sbsPermissions: {},
      workflowPermissions: {},
      generalPermissions: {},
      generalPermissionOptions: [
        {
          column: 'workflows',
          icon: 'mdi-source-branch',
        },
        {
          column: 'tables',
          icon: 'mdi-table-multiple',
        },
        {
          column: 'sbs',
          icon: 'mdi-lan',
        },
        {
          column: 'tasks',
          icon: 'mdi-clipboard-multiple-outline',
        },
        {
          column: 'labels',
          icon: 'mdi-label-multiple-outline',
        },
        {
          column: 'members',
          icon: 'mdi-account-group',
        },
      ],
      workflowPermissionOptions: [
        {
          column: 'import',
          icon: 'mdi-import',
        },
        {
          column: 'configure',
          icon: 'mdi-cogs',
        },
        {
          column: 'start-session',
          icon: 'mdi-clock-start',
        },
      ],
      sbsPermissionOptions: [
        {
          column: 'create',
          icon: 'mdi-creation',
        },
        {
          column: 'update',
          icon: 'mdi-update',
        },
        {
          column: 'delete',
          icon: 'mdi-delete',
        },
        {
          column: 'import',
          icon: 'mdi-import',
        },
      ],
      isLoadingData: false,
    };
  },
  computed: {
    ...mapGetters(['project']),
  },
  watch: {
    selectedTeam: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    fetchData() {
      this.teamModules = [];
      this.sbsPermissions = {};
      this.workflowPermissions = {};
      this.generalPermissions = {};
      this.isLoadingData = true;
      getTeamModules(this.selectedTeam.id, this.project.id)
        .then((modules) => (this.teamModules = modules))
        .then(() => {
          return Promise.all([
            getTeamWorkflowPermissions(this.selectedTeam.id),
            getTeamGeneralPermissions(this.selectedTeam.id, this.project.id),
            getTeamSbsPermissions(this.selectedTeam.id),
          ]).then((responses) => {
            this.workflowPermissions = responses[0];
            this.generalPermissions = responses[1];
            this.sbsPermissions = responses[2];
            this.isLoadingData = false;
          });
        });
    },
    isGeneralPermissionActive(column) {
      return this.generalPermissions[column] || this.selectedTeam.is_admin;
    },
    isSbsPermissionActive(column) {
      return this.sbsPermissions[column] || this.selectedTeam.is_admin;
    },
    isWorkflowPermissionActive(column) {
      return this.workflowPermissions[column] || this.selectedTeam.is_admin;
    },
    isModuleActive(module) {
      return (
        this.teamModules.findIndex((item) => item.module === module.id) >= 0 ||
        this.selectedTeam.is_admin
      );
    },
    clickModuleForTeam(module) {
      if (!this.selectedTeam.is_admin) {
        module.loading = true;
        if (this.isModuleActive(module)) {
          deleteTeamModuleRelation(this.selectedTeam.id, module.id).then(
            (response) => {
              this.teamModules = this.teamModules.filter(
                (item) => item.module !== module.id
              );
              module.loading = false;
            }
          );
        } else {
          createTeamModuleRelation(this.selectedTeam.id, module.id).then(
            (response) => {
              this.teamModules.push(response);
              module.loading = false;
            }
          );
        }
      } else {
        this.$store.commit('showNotification', {
          content: 'Module cannot be disabled for admin team',
          color: 'info',
        });
      }
    },
    updateGeneralPermission(column, value) {
      if (!this.selectedTeam.is_admin) {
        createTeamGeneralPermissions(this.selectedTeam.id, {
          [column]: value,
        }).then((updatedPermission) => {
          this.generalPermissions = updatedPermission;
          return Promise.all([
            getTeamWorkflowPermissions(this.selectedTeam.id),
            getTeamSbsPermissions(this.selectedTeam.id),
          ]).then((responses) => {
            this.workflowPermissions = responses[0];
            this.sbsPermissions = responses[1];
          });
        });
      } else {
        this.$store.commit('showNotification', {
          content: 'Permission cannot be disabled for admin team',
          color: 'info',
        });
      }
    },
    updateWorkflowPermission(column, value) {
      if (!this.selectedTeam.is_admin) {
        createTeamWorkflowPermissions(this.selectedTeam.id, {
          [column]: value,
        }).then((updatedPermission) => {
          this.workflowPermissions = updatedPermission;
        });
      } else {
        this.$store.commit('showNotification', {
          content: 'Permission cannot be disabled for admin team',
          color: 'info',
        });
      }
    },
    updateSbsPermission(column, value) {
      if (!this.selectedTeam.is_admin) {
        createTeamSbsPermissions(this.selectedTeam.id, {
          [column]: value,
        }).then(
          (updatedPermission) => (this.sbsPermissions = updatedPermission)
        );
      } else {
        this.$store.commit('showNotification', {
          content: 'Permission cannot be disabled for admin team',
          color: 'info',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.team-modules-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;

  .team-module {
    height: 70px;
    border: solid 1px #e6e9f0;

    .inactive {
      opacity: 0.5;
    }

    &.team-module-active {
      border: solid 1px var(--v-primary-base);
    }

    &:hover {
      .team-module-overlay {
        pointer-events: all;
        opacity: 1;
      }
    }

    .team-module-overlay {
      z-index: 10;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(230, 233, 240, 0.5);
      transition: opacity 200ms;
      display: flex;
      align-items: center;
      justify-content: center;

      .team-module-overlay-btn {
        transition: 200ms;

        &:hover {
          background-color: var(--v-primary-base);
          color: white;
        }
      }
    }
  }
}
</style>
