<template>
  <div class="flex-grow-1">
    <dynamic-data-table
      :can-delete="true"
      :can-refresh="false"
      :is-loading="teamMembersStatus === 'loading'"
      :table-headers="teamMemberHeaders"
      :table-height="`${tableHeight}px`"
      :table-records="teamMembers"
      table-title=""
      @deleteItem="setupMemberDelete"
    >
      <template #table-actions>
        <v-dialog
          key="add-team-member"
          v-model="teamMemberDialog"
          max-width="500px"
          @click:outside="closeTeamMemberDialogs"
        >
          <template #activator="{ on: onDialog }">
            <v-tooltip left>
              <template #activator="{ on: onTooltip }">
                <v-icon
                  class="add-team-member-icon"
                  v-on="{ ...onDialog, ...onTooltip }"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>Add team member</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>Add new Team member</v-card-title>
            <v-form ref="team-member-form" @submit.prevent>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10" sm="12">
                      <v-autocomplete
                        v-model="teamMemberItem.id"
                        :items="filteredUsers"
                        :rules="[rules.required]"
                        cache-items
                        class="mx-4"
                        flat
                        hide-details
                        hide-no-data
                        item-text="email"
                        item-value="id"
                        label="User email"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="closeTeamMemberDialogs">
                  Cancel
                </v-btn>
                <v-btn color="primary" @click="saveTeamMember"> Save</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>

    <delete-dialog
      :dialog="teamMemberDeleteDialog"
      :title="`Are you sure you want to delete '${teamMemberItem.name}' from team?`"
      @closeDialog="closeTeamMemberDialogs"
      @deleteAction="deleteMemberFromTeam"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import {
  addTeamUser,
  deleteTeamUser,
  getTeamUsers,
} from '@/services/api/teams.api';
import { mapGetters } from 'vuex';
import { getProjectLicenseUsers } from '@/services/api/projects.api';

export default {
  name: 'ProjectTeamMembers',
  components: { DeleteDialog, DynamicDataTable },
  props: {
    selectedTeam: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      teamMemberHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'E-mail', value: 'email' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],

      rules: {
        required: (value) => !!value || 'Required.',
      },

      teamMembers: [],
      teamMembersStatus: '',

      teamMemberItem: {},
      teamMemberDialog: false,
      teamMemberDeleteDialog: false,

      licenseUsers: [],
      tableHeight: undefined,
    };
  },
  computed: {
    ...mapGetters(['project', 'authenticatedUser']),

    filteredUsers() {
      return this.licenseUsers.filter(
        (user) => !this.teamMembers.find((member) => member.id === user.id)
      );
    },
  },
  watch: {
    selectedTeam: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchTeamMembers();
        this.tableHeight = window.innerHeight - 59 - 64 - 45 - 72;
      },
    },
  },
  methods: {
    fetchTeamMembers() {
      this.teamMembersStatus = 'loading';
      getTeamUsers(this.selectedTeam.id, this.project.id).then(
        (teamMembers) => {
          teamMembers.map((item) => ({
            ...item,
            pro_user: !!item.pro_user,
          }));
          this.teamMembers = teamMembers;
          this.teamMembersStatus = 'success';
        }
      );
      getProjectLicenseUsers(this.project.id).then((licenseUsers) => {
        this.licenseUsers = licenseUsers;
      });
    },
    setupMemberDelete(teamMember) {
      if (this.selectedTeam.is_admin && this.teamMembers.length === 1) {
        this.$store.commit('showNotification', {
          content: `You can not remove last team's admin`,
          color: 'error',
        });
        return;
      }
      this.teamMemberItem = Object.assign({}, teamMember);
      this.teamMemberDeleteDialog = true;
    },
    deleteMemberFromTeam() {
      deleteTeamUser(
        this.selectedTeam.id,
        this.teamMemberItem.id,
        this.project.id
      )
        .then(() => {
          this.teamMembers = [
            ...this.teamMembers.filter(
              (member) => member.id !== this.teamMemberItem.id
            ),
          ];
        })
        .then(this.closeTeamMemberDialogs);
    },
    closeTeamMemberDialogs() {
      this.teamMemberItem = Object.assign({}, {});
      this.teamMemberDialog = false;
      this.teamMemberDeleteDialog = false;
      if (this.$refs['team-member-form']) {
        this.$refs['team-member-form'].reset();
      }
    },
    saveTeamMember() {
      addTeamUser(this.selectedTeam.id, this.project.id, this.teamMemberItem.id)
        .then((teamMember) => {
          this.teamMembers.push(teamMember);
        })
        .then(this.closeTeamMemberDialogs);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-team-member-icon {
  transition: 200ms;

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>
