<template>
  <div class="d-flex">
    <div
      class="ant-glass-background ant-border-right radius-0 pa-2 overflow-y-auto"
      style="width: 300px"
    >
      <div v-if="teamsStatus === 'success'">
        <v-subheader>
          Project Teams
          <v-spacer />

          <v-dialog
            key="add-team"
            v-model="newTeamDialog"
            max-width="500px"
            @click:outside="
              newTeamDialog = false;
              newTeamName = undefined;
            "
          >
            <template #activator="{ on, attrs }">
              <v-icon class="add-team-icon" v-bind="attrs" v-on="on">
                mdi-plus
              </v-icon>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Add Team</span>
              </v-card-title>
              <v-form ref="form" @submit.prevent>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="newTeamName"
                          autofocus
                          label="Team name"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="error"
                    text
                    @click="
                      newTeamDialog = false;
                      newTeamName = undefined;
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="primary" @click="saveTeam()"> Save</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-subheader>
        <div
          v-for="team in teams"
          :key="team.id"
          :class="{ 'project-team-list-item-active': activeTeam === team }"
          class="pa-2 d-flex project-team-list-item"
          @click="activeTeam = team"
        >
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <v-icon
                class="project-team-list-item-icon mr-2"
                v-bind="attrs"
                v-on="on"
              >
                {{
                  team.is_admin ? 'mdi-account-key' : 'mdi-account-key-outline'
                }}
              </v-icon>
            </template>
            <span> {{ team.is_admin ? 'Admin team' : 'Not admin team' }}</span>
          </v-tooltip>
          {{ team.name }}
        </div>
      </div>
      <div v-else class="d-flex justify-center align-center fill-height">
        <ant-loading />
      </div>
    </div>
    <div v-if="activeTeam" class="d-flex flex-column flex-1 overflow-y-auto">
      <div class="ma-2 d-flex">
        <div class="pa-4 ant-glass-background d-flex align-center">
          <v-edit-dialog
            :return-value.sync="activeTeam.name"
            large
            @close="updatedTeamName = undefined"
            @open="updatedTeamName = activeTeam.name"
            @save="updateTeamName()"
          >
            {{ activeTeam.name }}
            <template #input>
              <v-text-field
                v-model="updatedTeamName"
                counter
                label="Edit"
                single-line
              />
            </template>
          </v-edit-dialog>
          <v-divider class="mx-2" vertical />
          <v-icon
            v-if="!activeTeam.is_admin"
            dense
            @click="deleteActiveTeamDialog = true"
          >
            mdi-delete
          </v-icon>
          <delete-dialog
            :dialog="deleteActiveTeamDialog"
            :title="`Are you sure you want to remove '${activeTeam.name}' from project?`"
            @closeDialog="deleteActiveTeamDialog = false"
            @deleteAction="deleteTeam"
          />
        </div>
      </div>
      <v-divider />
      <v-tabs vertical>
        <v-tab style="justify-content: left">
          <v-icon left> mdi-account-multiple</v-icon>
          Members
        </v-tab>
        <v-tab
          v-if="$can('access', 'project-settings-tables')"
          style="justify-content: left"
        >
          <v-icon left> mdi-table-multiple</v-icon>
          Tables
        </v-tab>
        <v-tab
          v-if="$can('access', 'project-settings-modules')"
          style="justify-content: left"
        >
          <v-icon left> mdi-apps</v-icon>
          Modules
        </v-tab>
        <v-tab-item>
          <project-team-members :selected-team="activeTeam" />
        </v-tab-item>
        <v-tab-item v-if="$can('access', 'project-settings-tables')">
          <project-team-permissions :selected-team="activeTeam" />
        </v-tab-item>
        <v-tab-item v-if="$can('access', 'project-settings-modules')">
          <project-team-modules :selected-team="activeTeam" />
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';
import DeleteDialog from '@/components/DeleteDialog';
import ProjectTeamMembers from '@/components/Project/Settings/ProjectTeamMembers';
import ProjectTeamPermissions from '@/components/Project/Settings/ProjectTeamPermissions';
import ProjectTeamModules from '@/components/Project/Settings/ProjectTeamModules';

export default {
  name: 'ProjectSettings',
  components: {
    ProjectTeamModules,
    ProjectTeamPermissions,
    ProjectTeamMembers,
    DeleteDialog,
    AntLoading,
  },
  data: () => {
    return {
      activeTeam: undefined,
      updatedTeamName: '',
      deleteActiveTeamDialog: false,
      newTeamDialog: false,
      newTeamName: undefined,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser', 'project', 'teams', 'teamsStatus']),
  },
  mounted() {
    this.fetchTeams();
  },
  methods: {
    fetchTeams() {
      this.$store.dispatch('fetchTeams', this.project.id);
    },
    saveTeam() {
      let body = {
        project: {
          id: this.project.id,
        },
        name: this.newTeamName,
        is_admin: false,
      };

      this.$store.dispatch('createTeam', { team: body }).then(() => {
        this.newTeamDialog = false;
        this.newTeamName = undefined;
      });
    },
    updateTeamName() {
      let body = {
        project: {
          id: this.project.id,
        },
        name: this.updatedTeamName,
      };

      this.$store.dispatch('updateTeam', {
        teamId: this.activeTeam.id,
        team: body,
      });
    },
    deleteTeam() {
      let body = {
        project: {
          id: this.project.id,
        },
      };
      this.$store
        .dispatch('deleteTeam', { teamId: this.activeTeam.id, body })
        .then(() => {
          this.activeTeam = undefined;
          this.deleteActiveTeamDialog = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-team-icon {
  transition: 200ms;

  &:hover {
    color: var(--v-primary-base);
  }
}

.project-team-list-item {
  cursor: pointer;

  &:hover {
    background-color: white;

    .project-team-list-item-icon {
      color: var(--v-primary-base);
      margin-right: 5px;
    }
  }

  &.project-team-list-item-active {
    background-color: white;

    .project-team-list-item-icon {
      color: var(--v-primary-base);
      margin-right: 5px;
    }
  }
}

::v-deep .v-window-item--active {
  display: flex;
  flex-grow: 1;
}
</style>
